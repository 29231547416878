<template>
<div class="comboDetail">
    <headComp></headComp>
    <breadComp></breadComp>

    <div class="centerWrapper">
        <div class="banner">
            <div class="name">
                <!-- <div class="belong">{{packageDetail.goodsPackageName}}</div> -->
                <div class="belong">{{goodsList.length}}件套</div>
                <div class="curr">{{showGoodsTypeName}}</div>
            </div>
            <el-carousel @change="bannerChange">
                <el-carousel-item v-for="(item,i) in bannerList" :key="i">
                    <img :src="item.cover?item.cover.split(',')[0]:require('../assets/imgs/place29.png')" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="tagline">{{packageDetail.goodsPackageName}}</div>
        <!-- <div class="intro">【全屋14件套】现代舒适转角沙发、法式奶油茶几+电视柜、奶油风餐桌椅、奶油牛奶绒布床、软包皮艺床、床头柜</div> -->
        <div class="intro">{{packageDetail.describe}}</div>
        <div class="rela">
            <!-- <div>
                <img src="../assets/imgs/solded2.png" />
                <span>已售</span>
                <span class="num">28</span>
            </div> -->
            <div>
                <img src="../assets/imgs/heat2.png" />
                <span>热度</span>
                <span class="num">{{packageDetail.hotValue}}</span>
            </div>
        </div>
        <div class="ope">
            <div class="price">
                <!-- 套装价 -->
                套装
                <!-- <span class="totalPrice">
                    <span>￥</span>
                    ￥{{packageDetail.totalPrice}}
                </span> -->
                <span>{{packageDetail.subtitle}}</span>
            </div>
            <el-button class="shopCart" type="danger" @click="joinCart" icon="el-icon-shopping-cart-2">加入购物车</el-button>
        </div>
        <div class="comboProduct">
            <div class="line">套餐产品{{goodsList.length}}</div>
            <img class="leftClick" src="../assets/imgs/leftArrow.png" :style="{'filter':limtSlide=='L'?'brightness(0.5)':'brightness(1)'}" v-show="goodsList.length>5" @click="clickSlide(1)"/>
            <img class="rightClick" src="../assets/imgs/rightArrow.png" :style="{'filter':limtSlide=='R'?'brightness(0.5)':'brightness(1)'}" v-show="goodsList.length>5" @click="clickSlide(0)"/>
            <div class="list" ref="goodsListDom">
                <div class="inner">
                    <div class="item" v-for="(item,i) in goodsList" :key="i" @click="clickGoods(item)">
                        <div class="cover">
                            <img :src="item.cover?item.cover.split(',')[0]:require('../assets/imgs/place30.png')" />
                        </div>
                        <p>{{item.goodsTypeName}}*{{item.goodsNumber}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="showArea">
            <div class="recommend">
                <div class="name">相似空间推荐</div>
                <div class="list">
                    <div class="item" v-for="(item,i) in similarityList" :key="i" @click="clickSimilarity(item)">
                        <div class="cover">
                            <img :src="item.cover?item.cover.split(',')[0]:require('../assets/imgs/place17.png')" />
                        </div>
                        <div class="text">{{item.goodsPackageName}}</div>
                    </div>
                </div>
            </div>
            <div class="show">
                <el-tabs v-model="activeName2" type="card" @tab-click="handleClick">
                    <el-tab-pane label="商品详情" name="1"></el-tab-pane>
                    <!-- <el-tab-pane label="品牌实力" name="2"></el-tab-pane> -->
                    <!-- <el-tab-pane label="定制流程" name="3"></el-tab-pane> -->
                    <el-tab-pane label="服务保障" name="4"></el-tab-pane>
                </el-tabs>
                <div class="area" v-show="activeName2==1">
                    <!-- <img v-for="i in 4" :key="i" src="../assets/imgs/place18.png"/> -->

                    <!-- <img v-for="(item,i) in detailImageList" :key="i" :src="item?item:require('../assets/imgs/place18.png')" />
                    <div class="empty" v-show="detailImageList.length==0">
                        <img src="../assets/imgs/place.png" />
                        <div>暂无商品详情</div>
                    </div> -->
                    <img v-for="(item,i) in packageDetail.itemList" :key="i" :src="item.cover" />
                    <div class="empty" v-show="packageDetail.itemList.length==0">
                        <img src="../assets/imgs/place.png" />
                        <div>暂无商品详情</div>
                    </div>
                </div>
                <div class="area" v-show="activeName2==2">
                    <!-- <img v-for="i in 4" :key="i" src="../assets/imgs/place18.png"/> -->
                    <!-- <div class="empty" v-show="true">暂无</div> -->
                </div>
                <div class="area" v-show="activeName2==3">
                    <!-- <img v-for="i in 4" :key="i" src="../assets/imgs/place18.png"/> -->
                    <!-- <div class="empty" v-show="true">暂无</div> -->
                </div>
                <div class="area" v-show="activeName2==4">
                    <img src="https://ai-smart-mall.oss-cn-chengdu.aliyuncs.com/fwbz.jpg" />
                    <div class="empty" v-show="false">
                        <img src="../assets/imgs/place.png" />
                        <div>暂无</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <loginBoxComp @close="showLoginBox=false" v-if="showLoginBox"></loginBoxComp>
    <shoppingTipComp :num="shoppingNum" v-show="showTip" @continu="showTip=false"></shoppingTipComp>
    <footerComp></footerComp>
</div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import shoppingTipComp from "@/components/shoppingTipComp.vue"
import loginBoxComp from "@/components/loginBoxComp"
import { getPackageDetail, saveShoppingCartBatch, getSimilarityPackageDetail } from "@/axios/api"
export default {
    components: {
        headComp,
        footerComp,
        breadComp,
        shoppingTipComp,
        loginBoxComp
    },
    data() {
        return {
            activeName2: "1",
            packageDetail: {
                itemList:[]
            },
            goodsList: [],
            shoppingNum: 0,
            showTip: false,
            similarityList: [], //相似套餐
            detailImageList: [],
            showGoodsTypeName: "",
            showLoginBox:false,
            bannerList:[],
            limtSlide:"R",
        }
    },
    methods: {
        // 轮播切换事件
        bannerChange(n, o) {
            // // console.log(n,o);
            this.showGoodsTypeName = this.goodsList[n].goodsTypeName
        },
        handleClick() {},
        gainGoodsPackageInfo() {
            getPackageDetail({
                goodsPackageId: this.$route.query.goodsPackageId
            }).then(res => {
                // console.log("商品套餐详情", res);
                if (res.code == 200) {
                    this.packageDetail = res.data;
                    if(res.data.cover){
                        this.bannerList=res.data.cover.split(",").map(cover=>{return{cover:cover}})
                    }else{
                        this.bannerList = res.data.itemList;
                    }
                    this.goodsList = res.data.itemList;
                    this.showGoodsTypeName = this.goodsList[0].goodsTypeName;

                    this.goodsList.forEach(item => {
                        if (item.detailImages) {
                            var arr = item.detailImages.split(",")
                            this.detailImageList.push(...arr)
                        }
                    })
                }
            })
        },
        // 加入购物车
        joinCart() {
            if(!localStorage.AOGEYA_loginInfo){
                this.showLoginBox=true;
                return
            }

            var arr = [];
            this.goodsList.forEach(item => {
                arr.push({
                    goodsId: item.goodsId,
                    number: item.goodsNumber
                })
            })
            saveShoppingCartBatch(arr).then(res => {
                // console.log("批量加入购物车结果", res);
                if (res.code == 200) {
                    this.shoppingNum = res.data.number;
                    this.showTip = true;
                } else {
                    // this.$message(res.msg || res.message)
                    this.$commonFun.wrongTip(this,res.msg);
                }
            })
        },
        // 获取相似套餐
        gainSimilarity() {
            getSimilarityPackageDetail({
                goodsPackageId: this.$route.query.goodsPackageId
            }).then(res => {
                // console.log("相似套餐", res);
                if (res.code == 200) {
                    this.similarityList = res.data;
                }
            })
        },
        // 点击相似套餐
        clickSimilarity(item) {
            // this.$route.query.goodsPackageId = item.goodsPackageId;
            // location.reload();

            this.$router.push({
                path:"/comboDetail",
                query:{goodsPackageId:item.goodsPackageId}
            })
        },
        // 点击单个商品
        clickGoods(item) {
            this.$router.push({
                path: "/furnitureDetail",
                query: { goodsId: item.goodsId }
            })
        },
        clickSlide(val){
            // console.log(this.$refs)            
            var dom=this.$refs.goodsListDom;
            var curPos=dom.scrollLeft;
            var innerWidth=dom.childNodes[0].clientWidth;
            var residueWidth=innerWidth-curPos-dom.clientWidth;
            var dis=0;
            var times=0;
            
            if(val){
                if(this.limtSlide=='R'){
                    // console.log(2)
                    return;
                }
                residueWidth=curPos-dom.clientWidth;
                if(residueWidth<=0){
                    dis=curPos;
                    this.limtSlide='R'
                }else{
                    dis=dom.clientWidth
                }
                var timer=setInterval(()=>{
                    curPos-=dis/20
                    dom.scrollLeft-=curPos;
                    times++;
                    if(times==20){
                        clearInterval(timer)
                    }
                },30)
            }else{
                if(this.limtSlide=='L'){
                    // console.log(3)
                    return;
                }
                if(residueWidth<dom.clientWidth){
                    dis=residueWidth
                    this.limtSlide='L'
                }else{
                    dis=dom.clientWidth;
                }
                var timer=setInterval(()=>{
                    curPos+=dis/20
                    dom.scrollLeft+=curPos;
                    times++;
                    if(times==20){
                        clearInterval(timer)
                    }
                },30)
            }
        }
    },
    created() {
        this.gainGoodsPackageInfo();
        this.gainSimilarity();
    },
}
</script>

<style lang="less" scoped>
.comboDetail {
    .banner {
        margin-top: 50px;
        width: 100%;
        height: 630px;
        position: relative;

        .el-carousel {
            height: 100%;

            /deep/.el-carousel__container {
                height: 100%;
            }

            /deep/.el-carousel__item {
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .name {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1000;
            height: 48px;
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);

            .belong {
                min-width: 157px;
                width: fit-content;
                padding: 0 30px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: url('../assets/imgs/effect4.png');
                background-size: 100% 100%;
                position: relative;
                z-index: 10;
            }

            .curr {
                min-width: 148px;
                width: fit-content;
                padding: 0 30px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: url('../assets/imgs/effect5.png');
                background-size: 100% 100%;
                margin-left: -20px;
                position: relative;
            }
        }
    }

    .tagline {
        // margin-top: 25px;
        padding: 25px 26px 0;
        // font-size: 24px;
        font-size: 30px;
        font-weight: 700;
        color: #333;
        background: rgba(245, 245, 245, 1);
    }

    .intro {
        // margin-top: 20px;
        padding: 25px 26px 0;
        // font-size: 24px;
        font-size: 22px;
        font-weight: 400;
        color: #333;
        background: rgba(245, 245, 245, 1);
    }

    .rela {
        display: flex;
        align-items: center;
        // margin-top: 20px;
        padding: 25px 26px 0;
        background: rgba(245, 245, 245, 1);

        &>div {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 700;
            color: rgba(153, 153, 153, 1);
            margin-right: 15px;
            padding-right: 15px;

            // border-right: 1px solid rgba(237, 237, 237, 1);
            img {
                margin-right: 6px;
                width: 26px;
                height: 31px;
            }

            .num {
                font-size: 20px;
                font-weight: 700;
                color: rgba(158, 110, 62, 1);
                margin-left: 6px
            }
        }
    }

    .ope {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(245, 245, 245, 1);
        padding: 0 26px 25px;

        .price {
            font-size: 30px;
            // font-size: 20px;
            font-weight: 700;
            color: #333;
            margin-top: 28px;
            display: flex;
            align-items: baseline;

            &>span.totalPrice {
                // font-size: 50px;
                font-size: 40px;
                font-weight: 700;
                color: rgba(212, 48, 48, 1);
                margin-left: 16px;

                &>span {
                    font-size: 30px;
                }
            }
            &>span{
                margin-left: 16px;
            }
        }

        .shopCart {
            // margin-left: 76px;
            // height: 73px;
            // width: 378px;
            margin-left: 56px;
            height: 63px;
            width: 320px;
            border-radius: 0;
            font-size: 21px;
            font-weight: 700;
            color: rgba(255, 255, 255, 1);
            background: rgba(200, 64, 57, 1);
            margin-bottom: -15px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            /deep/.el-icon-shopping-cart-2{
                font-size: 30px;
            }
        }
    }

    .comboProduct {
        margin-top: 76px;
        position: relative;
        .leftClick{
            position: absolute;
            left: -92px;
            top: 143px;
            cursor: pointer;
            width: 60px;
            height: 60px;
        }
        .rightClick{
            position: absolute;
            right: -92px;
            top: 143px;
            cursor: pointer;
            width: 60px;
            height: 60px;
        }

        .line {
            // font-size: 24px;
            font-size: 20px;
            font-weight: 700;
            color: #333;
            padding-left: 26px;
        }

        .list {
            margin-top: 40px;
            overflow-x: auto;
            &::-webkit-scrollbar{
                height: 0;
            }
            .inner{
                width: fit-content;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                .item {
                    width: 259px;
                    height: 259px;
                    background: rgba(240, 240, 240, 1);
                    box-sizing: border-box;
                    padding: 10px;
                    margin-right: 35px;
                    cursor: pointer;
                    border-radius: 6px;

                    &:last-child {
                        margin-right: 0;
                    }
                    &:hover{
                        .cover{
                            img{
                                width: 279px;
                                height: 231px;
                            }
                        }
                    }

                    .cover{
                        width: 100%;
                        height: 211px;
                        position: relative;
                        overflow: hidden;
                        border-radius: 6px;
                        img {
                            width: 259px;
                            height: 211px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            transition: all 0.3s;
                            border-radius: 6px;
                            object-fit: cover;
                        }
                    }

                    p {
                        height: 28px;
                        width: 100%;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        letter-spacing: 0px;
                        line-height: 28px;
                        color: #333;
                        margin: 0;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .showArea {
        margin-top: 44px;
        display: flex;
        align-items: flex-start;

        .recommend {
            width: 300px;
            border: 1px solid rgba(212, 212, 212, 1);

            .name {
                padding: 12px 20px;
                font-size: 20px;
                font-weight: 700;
                color: rgba(102, 102, 102, 1);
                border-bottom: 1px solid rgba(212, 212, 212, 1);
            }

            .list {
                display: flex;
                flex-direction: column;
                align-items: center;

                .item {
                    width: 266px;
                    margin-top: 16px;
                    margin-bottom: 22px;
                    cursor: pointer;

                    &:hover{
                        .cover{
                            img{
                                width: 286px;
                                height: 199px;
                            }
                        }
                    }
                    .cover{
                        width: 100%;
                        height: 179px;
                        position: relative;
                        overflow: hidden;
                        border-radius: 6px;
                        img {
                            width: 266px;
                            height: 179px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            transition: all 0.3s;
                            object-fit: cover;
                            border-radius: 6px;
                        }
                    }

                    .text {
                        margin-top: 5px;
                        font-size: 18px;
                        font-weight: 500;
                        color: rgba(56, 56, 56, 1);
                        text-align: center;
                    }
                }
            }
        }

        .show {
            margin-left: 20px;
            flex: 1;

            .el-tabs {
                /deep/.el-tabs__header {
                    border-color: transparent;
                }

                /deep/.el-tabs__nav {
                    border-color: transparent;
                }

                /deep/.el-tabs__item {
                    // height: 47px;
                    border-color: transparent;
                    font-size: 20px;
                    font-weight: 700;
                    color: rgba(102, 102, 102, 1);

                    &.is-active {
                        background: rgba(212, 48, 48, 1);
                        // color: rgba(212, 48, 48, 1);
                        // border-top: 3px solid rgba(212, 48, 48, 1);
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }

            .area {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }

                .empty {
                    padding: 50px 0;
                    text-align: center;

                    &>img {
                        width: 200px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
</style>
